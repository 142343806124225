<template>
    <div class="modal fade" ref="passwordResetModal" id="password-reset-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ $t('account.reinitialisation-mdp') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <label for="reset-email">{{$t('account.email')}}</label>
                        <input type="text" class="form-control" id="reset-email" v-model="email">
                        <transition name="fade" mode="out-in">
                            <div v-if="errors && errors.email">
                                <div v-for="error in errors.email" class="invalid" >
                                    {{error}}
                                </div>
                            </div>
                        </transition>
                        <label for="reset-password">{{$t('account.password')}}</label>
                        <input type="password" class="form-control" id="reset-password" v-model="password" autocomplete="true">
                        <transition name="fade" mode="out-in">
                            <div v-if="errors && errors.password">
                                <div v-for="error in errors.password" class="invalid" >
                                    {{error}}
                                </div>
                            </div>
                        </transition>
                        <label for="reset-password-confirm">{{$t('account.password-confirmation')}}</label>
                        <input type="password" class="form-control" id="reset-password-confirm" v-model="password_confirmation" autocomplete="true">
                        <transition name="fade" mode="out-in">
                            <div v-if="errors && errors.password_confirmation">
                                <div v-for="error in errors.password_confirmation" class="invalid" >
                                    {{error}}
                                </div>
                            </div>
                        </transition>
                    </form>
                </div>
                <div class="modal-footer">
                    <button ref="closePasswordResetModal" id="close-password-reset-modal" type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" @click="loading=false">{{$t('global.annuler')}}</button>
                    <button :disabled="loading" type="button" class="btn btn-sm btn-primary" @click="onSubmit">
                        <span v-if="loading" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
                        <span v-if="loading" >{{$t('global.chargement')}}</span>
                        <span v-if="!loading"><i class="bi bi-box-arrow-in-right me-3"></i>{{$t('global.connexion')}}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import authApi from '../../api/auth'
    export default {
        name: "ModalPasswordReset",
        data() {
            return {
                password: null,
                password_confirmation: null,
                email: null,
                loading: null,
                errors: null
            }
        },
        props: {
            token: {
                required: false,
                type: String
            }
        },
        methods: {
            async onSubmit() {
                this.loading = true
                try {
                    let res = await authApi.passwordReset({
                        token: this.token,
                        email: this.email,
                        password:  this.password,
                        password_confirmation:  this.password_confirmation
                    })
                    $('#password-reset-modal').modal('hide')

                    Vue.$toast.open({
                        message: this.$t('account.reinitialisation-mdp-ok'),
                        type: 'success',
                    });
                    $('#connexionModal').modal('show')
                }catch (e) {
                    this.errors = e.response.data.errors
                    if(this.errors) {
                        let string = ''
                        for (const [key, value] of Object.entries(this.errors)) {
                            value.forEach(m=> {
                                string += '-' + m + '<br>'
                            })
                        }
                        Vue.$toast.open({
                            message: this.$t('global.erreur')+ '<br>' + string,
                            type: 'error',
                        });
                    }else {
                        Vue.$toast.open({
                            message: this.$t('global.erreur'),
                            type: 'error',
                        });
                    }
                }
                this.loading = false
            }
        }
    }
</script>

<style scoped>

</style>
